<template>
  <div>
    <div class="w-screen h-screen bg-white flex items-center px-6">
    </div>
  </div>
</template>

<script>
// import $ from "jquery";
// import {get} from "lodash";
// import {json} from "overmind";
export default {
  components: {
  },
  data() {
    return {
    };
  },
  async mounted() {
    if (!this.$route.params?.email || !this.$route.params?.token) {
      this.actions.alert.showError({message: 'Something went wrong! Please try again'});
      return false;
    }
    try {
      const {verifyEmail} = await this.actions.verifyEmail({
        email: this.$route.params.email,
        token: this.$route.params.token
      });
      if (verifyEmail) {
        const { token, userId } = await this.actions.getStoredAuthToken();
        this.actions.alert.showSuccess({message: 'Thank you for confirming your email!'});
        if (token && userId) {
          await this.actions.loginWithToken({ token, userId });
          // await this.actions.user.saveUser({
          //   where: {id: this.state.currentUser?.id},
          //   data: {metadata: {...this.state.currentUser?.metadata, firstLogin: true}}
          // })
          // this.$router.push({name: 'Home', query: {registered: 'true'}})
					// if (this.state.)
        } else {
          this.$router.push('/login')
        }
      } else {
        this.actions.alert.showError({message: 'Something went wrong! Please try again'})
        this.$router.push('/login')
      }
    } catch (e) {
      console.log(e.response)
      this.$router.push('/login')
    }
  },
};
</script>

<style lang="css" scoped>
</style>
